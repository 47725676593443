<template>
  <v-bottom-sheet v-model="sheet" inset max-width="500" v-if="value">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item v-bind="attrs" v-on="on">
        <v-list-item-avatar :color="fileColor()">
          <v-icon v-if="value.title" color="white">{{ fileIcon() }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>
            <span v-if="value.name">{{ value.name }}</span>
            <span v-if="value.title">{{ value.title }}</span>
          </v-list-item-title>
          <v-list-item-subtitle
            v-if="value.description"
            v-html="value.description"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-chip v-if="value.available" color="primary" outlined small>
            {{ value.size }}</v-chip
          >
          <v-chip v-else small color="danger" outlined>!</v-chip>
        </v-list-item-action>
      </v-list-item>
    </template>

    <v-card tile>
      <v-card-text class="title pt-8 text-center">
        {{ value.url ? `Link «${value.title}»` : `Datei «${value.title}»` }}
      </v-card-text>
      <v-card-text class="display text-center">
        <v-avatar :color="fileColor(value)">
          <v-icon v-if="value.title" color="white">{{
            fileIcon(value)
          }}</v-icon>
        </v-avatar>
        <br />

        <span v-if="value.fileName">
          {{ value.fileName }}
        </span>
        <span v-if="value.url">
          {{ value.url }}
        </span>
      </v-card-text>
      <v-card-text v-if="value.description" class="pt-0 body-1 text-center">
        {{ value.description }}
      </v-card-text>
      <v-card-text class="text-center pt-4">
        <v-chip class="mx-1"
          >{{ formatDate(value.lastModifiedDate) }},
          {{ value.lastModifiedTime }}</v-chip
        >
        <v-chip class="mx-1">{{
          value.visibility ? value.visibility.description : ''
        }}</v-chip>
        <v-chip class="mx-1" v-if="!value.url">{{ value.size }}</v-chip>
      </v-card-text>
      <v-divider />
      <v-list v-if="value.url">
        <v-list-item :href="value.url" target="_blank">
          <v-list-item-icon><v-icon>mdi-link</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link in neuem Tab öffnen
          </v-list-item-content></v-list-item
        >
        <v-list-item @click="copyToClipboard(value.url)">
          <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link in die Zwischenablage kopieren
          </v-list-item-content></v-list-item
        >
      </v-list>
      <v-list v-else>
        <v-list-item
          @click="apiDocument({ id: value.id })"
          v-if="openFileExtensions.includes(value.fileExtension)"
        >
          <v-list-item-icon
            ><v-icon>mdi-file-document</v-icon></v-list-item-icon
          >
          <v-list-item-content>Datei öffnen</v-list-item-content></v-list-item
        >
        <v-list-item
          @click="apiDocument({ id: value.id, openInNew: true })"
          v-if="openFileExtensions.includes(value.fileExtension)"
        >
          <v-list-item-icon><v-icon>mdi-open-in-new</v-icon></v-list-item-icon>
          <v-list-item-content
            >Datei in neuem Tab öffnen</v-list-item-content
          ></v-list-item
        >
        <v-list-item @click="apiDocument({ id: value.id, download: true })">
          <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
          <v-list-item-content
            >Datei herunterladen
          </v-list-item-content></v-list-item
        >
        <v-list-item
          @click="
            copyToClipboard(
              `https://intern.gymkirchenfeld.ch/document/${value.id}`
            )
          "
        >
          <v-list-item-icon><v-icon>mdi-content-copy</v-icon></v-list-item-icon>
          <v-list-item-content
            >Link zur Datei in die Zwischenablage kopieren
          </v-list-item-content></v-list-item
        >
      </v-list>

      <v-divider />
      <v-card-actions>
        <v-btn @click="sheet = false" text block>schliessen</v-btn>
      </v-card-actions>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
import { formatDate } from 'common/utils/date';
import { copyToClipboard } from 'common/utils/helper.js';

export default {
  props: {
    value: null,
  },
  data() {
    return {
      sheet: false,
      openFileExtensions: ['pdf', 'png', 'svg', 'jpg', 'jpeg', 'gif'],
    };
  },
  methods: {
    copyToClipboard,
    formatDate,
    fileIcon() {
      if (this.value.url != null) {
        return 'mdi-link';
      }
      if (this.value.fileExtension == null) return 'mdi-file-outline';
      if (this.value.fileExtension.startsWith('do')) {
        return 'mdi-file-word-outline';
      }
      if (this.value.fileExtension.startsWith('xl')) {
        return 'mdi-file-excel-outline';
      }
      if (
        this.value.fileExtension.startsWith('pp') ||
        this.value.fileExtension == 'thmx'
      ) {
        return 'mdi-file-powerpoint-outline';
      }
      switch (this.value.fileExtension) {
        case 'zip':
          return 'mdi-zip-box-outline';
        case 'pdf':
          return 'mdi-file-document-outline';
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tif':
        case 'svg':
          return 'mdi-file-image-outline';

        default:
          return 'mdi-file-outline';
      }
    },
    fileColor() {
      if (this.value.url != null) {
        return 'primary';
      }
      if (this.value.fileExtension == null) return '';
      if (this.value.fileExtension.startsWith('do')) {
        return '#035AC1';
      }
      if (this.value.fileExtension.startsWith('xl')) {
        return '#107B41';
      }
      if (
        this.value.fileExtension.startsWith('pp') ||
        this.value.fileExtension == 'thmx'
      ) {
        return '#D4512D';
      }
      switch (this.value.fileExtension) {
        case 'zip':
          return 'yellow';
        case 'pdf':
          return '#f40f02';
        case 'gif':
        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'tif':
        case 'svg':
          return 'info';

        default:
          return 'grey';
      }
    },
  },
};
</script>
