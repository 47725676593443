<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-system-bar>{{ item.title }}</v-system-bar>

          <v-card-text v-if="item.description"
            ><Markdown :value="item.description"
          /></v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="peopleA && peopleA.length > 0">
        <v-card
          ><v-system-bar
            >Ansprechperson{{ peopleA.length > 1 ? "en" : "" }}</v-system-bar
          >

          <PersonItem
            v-for="(person, index) in peopleA"
            :key="index"
            :value="person"
          />
        </v-card>
      </v-col>
      <v-col v-if="peopleV && peopleV.length > 0">
        <v-card
          ><v-system-bar
            >Zuständige Person{{ peopleV.length > 1 ? "en" : "" }}</v-system-bar
          >
          <PersonItem
            v-for="(person, index) in peopleV"
            :key="index"
            :value="person"
          />
        </v-card>
      </v-col>

      <v-col v-if="item.documents && item.documents.length > 0">
        <v-card
          ><v-system-bar
            >Dokument{{ item.documents.length > 1 ? "e" : "" }}</v-system-bar
          >

          <DocumentItem
            v-for="(document, index) in item.documents"
            :key="'d' + index"
            :value="document"
          >
          </DocumentItem>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocumentItem from "@/components/DocumentItem.vue";
import PersonItem from "@/components/PersonItem.vue";

export default {
  components: { DocumentItem, PersonItem },
  props: ["id"],
  data() {
    return {
      item: {},
      loading: false,
    };
  },
  computed: {
    peopleA() {
      if (!this.item || !this.item.people || this.item.people.length == 0)
        return null;
      return this.item.people
        .filter((el) => el.role.code == "a")
        .map((el) => el.person);
    },
    peopleV() {
      if (!this.item || !this.item.people || this.item.people.length == 0)
        return null;
      return this.item.people
        .filter((el) => el.role.code == "v")
        .map((el) => el.person);
    },
  },
  watch: {
    id() {
      this.fetchData();
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.item = await this.apiGet({
        resource: "document/indexentry",
        id: this.id,
      });
      this.loading = false;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
